<template>
    <v-container id="BollettaInterattivaDatiPagamentoTestata3Dt">
        <v-row no-gutters>
            <div class="monocol contentCart">
                        <!--v-row-->
                <div class="monocol sectionParteSuperiore" style="text-align:right;">
                <!--v-col cols="6"-->

                    <v-col cols="12" v-if="documentData.document_payment_debt !== 0">
                        <div class="font-weight-light px-4 doc_amount_label">{{$t("message." + documentData.dati_pagamento_fields.doc_amount.label)}}</div>
                        <div v-if="documentData.dati_pagamento_fields.doc_amount.format == 'amount'" class="pb-1 font-weight-light px-4 doc_amount_value">
                            {{ documentData.dati_pagamento_fields.doc_amount.value | formatAmount(documentData.dati_pagamento_fields.doc_amount.format_show, documentData.currency_sigle) }}
                        </div>
                        <div v-else-if="documentData.dati_pagamento_fields.doc_amount.format == 'number'" class="pb-1 font-weight-light px-4 doc_amount_value">
                            {{ documentData.dati_pagamento_fields.doc_amount.value | formatNumber(documentData.dati_pagamento_fields.doc_amount.format_show) }}
                        </div>
                        <div v-else class="pb-1 font-weight-light px-4 doc_amount_value">
                            {{ documentData.dati_pagamento_fields.doc_amount.value }}
                        </div>
                    </v-col>
                    <v-col cols="12">
                        <div class="font-weight-bold px-4 doc_payment_debt">{{$t("message." + documentData.dati_pagamento_fields.doc_payment_debt.label)}}</div>
<!--                                    <div class="font-weight-bold px-4">{{ documentData.document_payment_debt + ' euro' }}</div>-->
                        <div v-if="documentData.dati_pagamento_fields.doc_payment_debt.format == 'amount'" class="font-weight-bold px-4 doc_payment_debt_value">
                            {{ documentData.dati_pagamento_fields.doc_payment_debt.value | formatAmount(documentData.dati_pagamento_fields.doc_payment_debt.format_show, documentData.currency_sigle) }}
                        </div>
                        <div v-else-if="documentData.dati_pagamento_fields.doc_payment_debt.format == 'number'" class="font-weight-bold px-4 doc_payment_debt_value">
                            {{ documentData.dati_pagamento_fields.doc_payment_debt.value | formatNumber(documentData.dati_pagamento_fields.doc_payment_debt.format_show) }}
                        </div>
                        <div v-else class="font-weight-bold px-4 doc_payment_debt_value">
                            {{ documentData.dati_pagamento_fields.doc_payment_debt.value }}
                        </div>
                    </v-col>
                <!--/v-col-->
                </div>

                <div class="monocol sectionParteInferiore" style="text-align:right;">

                <!--v-col cols="6"-->
                    
                    <!--   Section ok pagamento  -->

                    <div v-if="documentData.bolletta_interattiva_esponi_stato_pagamento==1 &&
                        (bolletta_interattiva_document_payment_status==0 || bolletta_interattiva_document_payment_status==3 || bolletta_interattiva_document_payment_status==4)" class="datacontainer">
                        <div v-if="bolletta_interattiva_document_payment_status==0">
                            <div class="notifiedUpperMessageCanceledContainer">
                            <v-icon class="notifiedImageCanceledContainer">mdi-emoticon-sad-outline</v-icon>
                            <div>
                                <span class="notification" v-html="$t('message.bolletta_interattiva_dati_pagamento_pagamento_annullato')"></span>
                            </div>
                            </div>
                        </div>
                        <div v-if="bolletta_interattiva_document_payment_status==3">
                            <div class="notifiedUpperMessageValidateContainer">
                            <v-icon class="notifiedImageValidateContainer">mdi-timer-sand</v-icon>
                            <div>
                                <span class="notification" v-html="$t('message.bolletta_interattiva_dati_pagamento_pagamento_davalidare')"></span>
                            </div>
                            </div>
                        </div>
                        <div v-if="bolletta_interattiva_document_payment_status==4">
                            <div class="notifiedUpperMessagePaidContainer">
                                <img :src="getPaymentStatusIco()" id="payment_status_ico" style="height: 1.25em; width: 1.25em;" />
                            <div>
                                <span class="notification" v-html="$t('message.bolletta_interattiva_dati_pagamento_pagamento_ok')"></span>
                            </div>
                            </div>
                        </div>
                        </div>

                    
                    <!-- Section Paga Ora--> 
                    <v-row v-if="(bolletta_interattiva_document_payment_status==1 || bolletta_interattiva_document_payment_status==2)
                                 && (documentData.document_payment!='rid' ||  documentData.bolletta_interattiva_esponi_rid!=1) && documentData.bolletta_interattiva_esponi_paga_ora==1" justify="right">
                        <v-row v-if="bolletta_interattiva_pagaora_nexi_attivazione" justify="right">
                            <v-col cols="12" align="right">
                                <div class="text-subtitle-1">{{$t('message.bolletta_interattiva_pagaora_nexi')}}</div>
                                <img style="max-width: 15%;" :src="getNexiLogo()" id="datiPagamentoNexiLogo" class="pr-0"/>
                                <v-btn
                                    style="border-radius: 8px !important; max-width: 50%;"
                                    class="black--text"
                                    id="buttonPagaOraNexi"
                                    @click="dufercoPagamento()">
                                    {{$t('message.button_pagaora_nexi_3Dt')}}
                                </v-btn>
                            
                                <!--div v-if="documentData.document_payment_debt > 0" class="documentPaymentExpiryText text-subtitle-2 pt-2 font-weight-light"> {{ 'Scadenza ' + documentData.document_payment_expiry }}</div-->
                            </v-col>
                            <v-col cols="12">
                                <img/>
                            </v-col>
                        </v-row>
                        <!--v-row id="rowBollettino">
                            <v-col cols="12" align="center" v-if="bolletta_interattiva_pagaora_boll">
                                <div class="text-subtitle-1">{{$t('message.bolletta_interattiva_pagaora_boll')}}</div>
                                <img style="max-width: 15%;" :src="getBollettinoLogo()" id="datiPagamentoBollettinoLogo" class="pl-1"/>
                                <v-btn
                                    style="border-radius: 8px !important; max-width: 60%;"
                                    class="black--text"
                                    id="buttonBollettino"
                                    @click="downloadBollettino()">
                                    {{$t('message.bolletta_interattiva_button_pagaora_boll')}}
                                </v-btn>
                            </v-col>
                        </v-row-->

                    
                    </v-row>

                    <!--   Rid  -->
                    <v-row v-if="documentData.document_payment!='rid'  && documentData.bolletta_interattiva_esponi_rid==1 ">
                        <div class="datacontainer">
                        <v-img class="ridImage" :src="getRidImage()" contain />
                        </div>
                        <div class="datacontainer">
                        <span class="ridtitle" v-html="$t('message.bolletta_interattiva_rid_label')"></span>
                        </div>
                        <div class="datacontainer">
                        <span class="ridtext" v-html="$t('message.bolletta_interattiva_rid_text')"></span>
                        </div>
                        <div class="datacontainer">
                        <v-btn v-bind="size" :href=documentData.bolletta_interattiva_rid_link target="_blank" id="buttonRid" class="ridButton" depressed
                            outlined rounded> <span class="ridButton_content" v-html="$t('message.bolletta_interattiva_rid_button')"></span>
                        </v-btn>
                        </div>
                    </v-row>

                    

                    <!--v-row v-if="documentData.bolletta_interattiva_esponi_stato_pagamento==1" class="datacontainer">
                            <div class="notifiedUpperMessagePaidContainer">
                                <v-icon class="notifiedImagePaidContainer">mdi-check-circle-outline</v-icon>
                                <span class="pagamentoStatus" v-html="$t('message.bolletta_interattiva_dati_pagamento_status')"></span>
                            </div>
                    </v-row-->

                    

                <!--/v-col-->
                </div>
            <!--/v-col-->
            </div>
        </v-row>
        <!-- Nexi Duferco Pagmento error -->
        <v-dialog
            v-model="showNexiErrorDialog"
            width="500px"
            >
            <v-card>
                <v-card-text class="text-subtitle-1 pa-5">
                    {{$t('message.bolletta_interattiva_pagaora_nexi_error')}}
                </v-card-text>
                <v-card-actions>
                    <v-btn block @click="reloadOnDufercoPagamentoError()">Chiudi</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { services } from '../../../scripts/services/serviceBuilder'
import updateToken from '../../../middleware/update-token';

import Vue from "vue";

export default {
    name: 'BollettaInterattivaDatiPagamentoTestata3Dt',
    data: function() {
        return {
            windowWidth: window.innerWidth,           

            bolletta_interattiva_pagaora_nexi_attivazione: false,
            bolletta_interattiva_pagaora_pagopa_attivazione: false,
            bolletta_interattiva_pagaora_boll: false,

            showNexiErrorDialog: false,

            bolletta_interattiva_document_payment_status: -1,
        }
    },
    props: {
        documentData: {
            type: Object,
            default: null
        }
    },
    computed: {

    },
    components: {
        
    },
    methods: {
        getRidImage: function() {
                return './' + location.hostname + '/images/bollettainterattiva/rid.jpg';
        },
        getNexiLogo: function () {
            return "./" + location.hostname + "/images/bollettainterattiva/nexi_logo.png";
        },
        getBollettinoLogo: function () {
            return "./" + location.hostname + "/images/bollettainterattiva/bollettino_icon.jpg";
        },
        getPaymentStatusIco: function () {
            return "./" + location.hostname + "/images/bollettainterattiva/check_mark.svg";
        },
        getImportoValue: function(value){
            if(value.includes('euro'))
                return value;
            return value + ' euro';
        },
        dufercoPagamento: function(){
            updateToken(Vue.prototype.$isAuthenticated.value);

            let that = this;
            let requests = [];
            //Calling api_duferco_verifica before proceeding on api_duferco_pagamento
            requests.push(services.apiCallerBollettaInterattivaDuferco.getDufercoVerifica(that.documentData.crypto));
            Promise.all(requests).then((responses) => {
                let verificaValue = responses[0].data;
                console.log("Verifica ha valore:", verificaValue);
                if(verificaValue === 1){
                    //OK
                    //Calling profiloDuferco
                    requests = [];
                    requests.push(services.apiCallerBollettaInterattivaDuferco.getDufercoProfilo(that.documentData.crypto));
                    Promise.all(requests).then((responses) => {
                        let profiloDuferco = responses[0].data;
                        //Calling api_duferco_pagamento
                        requests = [];
                        requests.push(services.apiCallerBollettaInterattivaDuferco.getDufercoPagamento(that.documentData.crypto));
                        Promise.all(requests).then((responses) => {
                            let postData = responses[0].data.post_data;
                            let nexiUrl = responses[0].data.url;
                            //Preparing url = /nexiEsito?id={crypto}&annullo=0 
                            // url_back = /nexiAnnullo?id={crypto}&annullo=1
                            postData.url = window.location.origin + '/nexiEsito?id=' + that.documentData.crypto + '&annullo=0'
                            postData.url_back = window.location.origin + '/nexiAnnullo?id=' + that.documentData.crypto + '&annullo=1'
                            if(postData === null || nexiUrl === null){
                                //Error
                                that.showNexiErrorDialog = true;
                            }else{
                                //Ok go for payment on Nexi
                                //TODO: use postData and nexiUrl to call NEXI services
                                if(profiloDuferco &&  profiloDuferco.emailFatturazione)
                                    postData['mail'] = profiloDuferco.emailFatturazione;
                                
                                postData['descrizione'] = '_*' + that.documentData.document_number + '__';
                                
                                that.postForm (nexiUrl,postData, 'post');
                                /*services.apiCallerBollettaInterattivaDuferco.postNexiPagamento(nexiUrl,postData).then((response)=>{
                                    console.log(response);
                                });*/
                            }
                        });
                    });
                }else{
                   // Error
                   that.showNexiErrorDialog = true;
                }
            });
            
        },
        postForm(path, params, method) {
            method = method || 'post';

            var form = document.createElement('form');
            form.setAttribute('method', method);
            form.setAttribute('action', path);

            for (var key in params) {
                var hiddenField = document.createElement('input');
                hiddenField.setAttribute('type', 'hidden');
                hiddenField.setAttribute('name', key);
                hiddenField.setAttribute('value', params[key]);

                form.appendChild(hiddenField);
            }

            document.body.appendChild(form);
            form.submit();
        },
        dufercoVerifica: function(){
            let that = this;
            let requests = [];
            //Calling api_duferco_verifica before proceeding on api_duferco_pagamento
            requests.push(services.apiCallerBollettaInterattivaDuferco.getDufercoVerifica(that.documentData.crypto));
            return Promise.all(requests);
        },
        reloadOnDufercoPagamentoError: function(){
            let that = this;
            that.showNexiErrorDialog = false;
            location.reload();
        },
        /*downloadBollettino: function() {
            let that = this;

            services.apiCallerMedia.download(that.documentData.detail)
                .then(response =>  {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'file.pdf');
                    document.body.appendChild(link);
                    link.click();
                })               
                .catch(error => {
                    console.log(error);
                });
        },*/
            
    },
	created: async function () {
        let that = this;
       
        that.bolletta_interattiva_pagaora_pagopa_attivazione = Boolean(Number(that.documentData.bolletta_interattiva_pagaora_pagopa_attivazione));
        that.bolletta_interattiva_pagaora_nexi_attivazione = Boolean(Number(that.documentData.bolletta_interattiva_pagaora_nexi_attivazione));

        that.bolletta_interattiva_pagaora_boll = Boolean(Number(that.documentData.bolletta_interattiva_pagaora_boll));

        if(!that.documentData.bolletta_interattiva_esponi_stato_pagamento_data || that.documentData.bolletta_interattiva_esponi_stato_pagamento_data.toUpperCase() == 'DB')
            that.bolletta_interattiva_document_payment_status = that.documentData.document_payment_status;
        else
          {
            that.dufercoVerifica().then((responses) => {
            that.bolletta_interattiva_document_payment_status  = responses[0].data;
            });
        }
        
	},
}
</script>